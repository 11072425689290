import React, { useEffect, useState } from 'react';
import styles from './UserListItem.module.scss';
import { PrimaryButton } from '../../components/button/PrimaryButton';
import { DangerButton } from '../../components/button/DangerButton';
import { Modal } from '../../components/Modal';
import DeleteUserConfirmation from './DeleteUserConfirmation';
import UserForm from './UserForm';

import { useSelector } from 'react-redux';
import { State } from '../../../../state/store';
import useModalOpener from '../../../hooks/common/useModalOpener';
import { ChatGptApi } from '../../../../state/api/chatgpt/ChatGptApi';
import UserLimitEditDialog from './components/UserLimitEditDialog';
import { ChatgptManageUsage } from '../../../../domain/chatgpt/ChatgptManageUsage';
import { ChatgptUsageCount } from '../../../../domain/chatgpt/ChatgptUsageCount';
import { UserWithManageUsageDetail } from '../../../../domain/user/UserWithManageUsageDetail';

export default function UserListItem(props: Props) {
  const { user, refresh } = props;

  const loginUser = useSelector((state: State) => state.authentication.user);

  const [isOpenEditDialog, openEditDialog, closeEditDialog] = useModalOpener();
  const [isOpenDeleteDialog, openDeleteDialog, closeDeleteDialog] =
    useModalOpener();
  const [isOpenLimitDialog, openLimitDialog, closeLimitDialog] =
    useModalOpener();

  const disabledDeleteButton = loginUser?.email === user.email;

  const [sendCount, setSendCount] = useState<number | undefined>();

  const [chatGptUsageType, setChatGptUsageType] = useState<
    number | undefined
  >();
  const [sendLimit, setSendLimit] = useState<number | undefined>();

  const [manageUsage, setManageUsage] = useState<
    ChatgptManageUsage | undefined
  >();

  const isChatGptEnable = chatGptUsageType!! != 0;

  useEffect(() => {
    setupManageUsage(user);
    setSendLimit(user.sendLimit);
    setChatGptUsageType(user.usageType);
    setSendCount(user.sendCount);
  }, []);

  const renderAdminLabel = () => {
    if (!user?.isAdmin) return null;

    return <div className={styles.adminLabel}>管理者</div>;
  };

  const onRequestCloseLimitDialog = () => {
    loadManageSetting();
    if (isChatGptEnable) {
      // 有効になった場合 現在の送信数等を再取得
      loadUserUsageCount();
    }
    closeLimitDialog();
  };

  const loadManageSetting = () => {
    let mounted = true;
    ChatGptApi.findManageSetting(user!!.id).then((res) => {
      if (mounted) {
        if (res instanceof ChatgptManageUsage) {
          setManageUsage(res);
          setSendLimit(res.sendLimit);
          setChatGptUsageType(res.usageType);
        }
      }
    });
    return () => {
      mounted = false;
    };
  };

  const loadUserUsageCount = () => {
    let mounted = true;
    ChatGptApi.getUserUsageCount(user!!.id).then((res) => {
      if (mounted) {
        if (res instanceof ChatgptUsageCount) {
          setSendCount(res.sendCount);
        }
      }
    });
    return () => {
      mounted = false;
    };
  };

  // userWithAiUsageManageから
  const setupManageUsage = (value: UserWithManageUsageDetail) => {
    setManageUsage(
      new ChatgptManageUsage(
        value.id,
        value.usageType,
        value.textLimit,
        value.fileLimit,
        value.sendLimit,
      ),
    );
  };

  const renderChatGPTLabel = () => {
    return (
      <div className={styles.chatGptInfo}>
        <div>ChatGPT使用量：</div>
        <div>{isChatGptEnable ? '有効' : '無効'}</div>
        {isChatGptEnable && (
          <div className={styles.limitInfo}>
            送信数: {sendCount}/{sendLimit}
          </div>
        )}
        {!isChatGptEnable && <div className={styles.limitInfo}></div>}
        <div className={styles.editButton}>
          <PrimaryButton onClick={openLimitDialog}>制限編集</PrimaryButton>
          <Modal
            open={isOpenLimitDialog}
            onRequestClose={onRequestCloseLimitDialog}
          >
            <UserLimitEditDialog
              user={user}
              usageInfo={manageUsage}
              onRequestClose={onRequestCloseLimitDialog}
              refresh={refresh}
            />
          </Modal>
        </div>
      </div>
    );
  };

  return (
    <li className={styles.row}>
      <div className={styles.userInfo}>
        <div className={styles.nameArea}>
          <div className={styles.name}>{user.displayName}</div>
          <div className={styles.email}>{user.email}</div>
        </div>
        {renderAdminLabel()}
        {renderChatGPTLabel()}
      </div>
      <div className={styles.buttonArea}>
        <div className={styles.editButtonArea}>
          <PrimaryButton onClick={openEditDialog}>編集</PrimaryButton>
          <Modal open={isOpenEditDialog} onRequestClose={closeEditDialog}>
            <UserForm
              user={user}
              type={'update'}
              onRequestClose={closeEditDialog}
              refresh={refresh}
            />
          </Modal>
        </div>
        <div>
          <DangerButton
            onClick={openDeleteDialog}
            disabled={disabledDeleteButton}
          >
            削除
          </DangerButton>
          <Modal open={isOpenDeleteDialog} onRequestClose={closeDeleteDialog}>
            <DeleteUserConfirmation
              user={user}
              onRequestClose={closeDeleteDialog}
              refresh={refresh}
            />
          </Modal>
        </div>
      </div>
    </li>
  );
}

type Props = {
  user: UserWithManageUsageDetail;
  refresh: () => void;
};
